export const font = 'Poppins'
export const fontFallback = `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`
export const fontFamily = `${font},${fontFallback}`

export const accentColor = '#0275d8'
export const secondaryAccentColor = '#227c31'
export const white = '#fff'
export const headingColor = '#222'
export const bodyColor = '#333'

export const hoverAccentColor = '#014c8c'
export const hoverSecondaryColor = '#165821'

export const fontSizeDefaults = {
  h1: '2.25em',
  h3: '2em',
  p: '1.5em',
  a: 'inherit',
  button: '1em',
  input: '1em',
}

export const fontColorDefaults = {
  h1: headingColor,
  h3: headingColor,
  p: bodyColor,
  a: accentColor,
}

export default {
  font,
  fontFallback,
  fontFamily,
  accentColor,
  white,
  headingColor,
  bodyColor,
  fontSizeDefaults,
  fontColorDefaults,
}
