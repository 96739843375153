import styled from 'styled-components'

import * as S from '../../style'

export const H3 = styled(S.H3)`
  font-size: 20;
`

export const Container = styled(S.Container)``

export const Button = styled(S.Button)`
  text-decoration: inherit;
  background: ${({ active }) => (active ? S.Theme.accentColor : '#aaa')};
`

export const Row = S.Row
