import * as R from 'ramda'

import { directions } from '../constants/index'

// makeLabelObject :: List(Tuple(v, k)) -> { k: v }
// Reduces a list of tuples containing a label name and ID to an object
// with IDs as keys and label names as values
const makeLabelObject = (acc, [label, id]) => ({ ...acc, [id]: label })

// Casts a list of GraphQL Edges to JavaScript arrays
// so we can work with them using Array.prototype methods,
// which Ramda uses under the hood
const castToArray = x => (!x ? [] : [...x])
//const gqlListToJSArray = R.pipe(R.of, R.flatten)

export const getUniqueLabels = R.pipe(
  R.path(['repository', 'issues', 'edges']),
  castToArray,
  R.map(
    R.pipe(
      R.path(['node', 'labels', 'edges']),
      R.map(R.prop('node')),
      R.map(R.props(['name', 'id'])),
    ),
  ),
  R.reject(R.isEmpty), // remove empty arrays (issues with no labels)
  R.uniqBy(R.head), // remove duplicate labels
  R.unnest,
  R.reduce(makeLabelObject, {}),
)

export const getIssues = R.pipe(
  R.path(['repository', 'issues', 'edges']),
  castToArray,
  R.map(R.prop('node')),
  R.indexBy(R.prop('id')),
  R.map(
    R.evolve({
      comments: R.prop('totalCount'),
      labels: R.pipe(
        R.prop('edges'),
        R.map(R.prop('node')),
        R.map(R.prop('name')),
      ),
      participants: R.pipe(
        R.prop('edges'),
        R.map(R.prop('node')),
        R.map(R.prop('avatarUrl')),
        R.head,
      ),
    }),
  ),
)

export const sortByColumn = sorting => {
  const column = R.head(R.keys(sorting))
  const direction = R.head(R.values(sorting))
  const comparator = direction === 'DESC' ? R.ascend : R.descend
  return column === 'comments'
    ? R.sortWith([comparator(R.prop('comments'))])
    : R.sortWith([comparator(R.pipe(R.prop(column), R.toLower))])
}

export const getNextDirection = direction => {
  console.log('direction', direction)
  console.log('direction === directions.DESC', direction === directions.DESC)
  const nextDirection =
    direction === directions.NONE
      ? directions.DESC
      : direction === directions.DESC
        ? directions.ASC
        : directions.NONE

  console.log('nextDirection', nextDirection)
  return nextDirection
}
