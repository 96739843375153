import React, { useState, useEffect } from 'react'
import { ApolloProvider, } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'

import { Router } from './Router'
import { Loading } from './Loading'
import { GlobalStyle } from '../style'
import { createClient } from '../state/client'



export default function Root() {
  const [client, setClient] = useState<ApolloClient<any>>()

  // Our implementation persists the Apollo cache to localStorage to prevent calling the GitHub API too often.
  // Because where the client serves data from depends on whether we have data in localStorage, createClient
  // returns a Promise that resolves as soon as the store has finished persisting.
  useEffect(() => {
    createClient().then(client => setClient(client))
  }, [])

  return client === undefined ? (
    <Loading />
  ) : (
      <ApolloProvider client={client} >
        <GlobalStyle />
        < Router />
      </ApolloProvider>
    )
}
