import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
    outline: 0 none;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
  }


  button {
    outline: 0 none;
    border: 0 none;
  }
`
