import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import * as T from './theme'

const justifyMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
}

const alignMap = { ...justifyMap, stretch: 'stretch' }

const textAlignMap = { l: 'left', r: 'right', c: 'center' }

const mq = {
  lg: _css =>
    css`
      @media (min-width: 992px) {
        ${_css}
      }
    `,
  md: _css =>
    css`
      @media (min-width: 768px) {
        ${_css}
      }
    `,
  sm: _css =>
    css`
      @media (min-width: 576px) {
        ${_css}
      }
    `,
}

export const Input = styled.input`
  padding: 8px;
  font-family: ${T.fontFamily};
  font-size: ${({ fs }) => fs || T.fontSizeDefaults['input']};
`

const BaseContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'row' ? 'row' : 'column')};
  justify-content: ${({ justify = 'center' }) => justifyMap[justify]};
  align-items: ${({ align = 'center' }) => alignMap[align]};
  padding: ${({ p }) => p || 0};
  margin: ${({ m }) => m || 0};
  width: 100%;
`

export const PageContainer = styled(BaseContainer)`
  ${mq.sm('max-width: 540px;')}
  ${mq.md('max-width: 720px;')}
  ${mq.lg('max-width: 1200px;')}
  margin: ${({ m }) => m || '0 auto'};

  display: flex;
`

export const Container = styled(BaseContainer)`
  margin: ${({ m }) => m || '0 auto'};

  text-align: ${({ ta }) => textAlignMap[ta] || textAlignMap['c']};

  max-width: 100%;
  flex-wrap: wrap;
`

export const Row = styled(Container)`
  flex-direction: row;
  padding: ${({ p }) => p || '2rem 1rem'};
`

const parsePercentage = n => parseFloat(100 / n, 10).toFixed(1)

const ProportionateBox = styled(BaseContainer)`
  width: ${({ col, gutter }) =>
    `calc(${parsePercentage(col)}% - ${gutter ? 2 * gutter : 0}px)`};
  margin: ${({ gutter }) => gutter + 'px'};
  flex-grow: 1;
`
// flex: ${({ col }) => `1 1 ${parsePercentage(col)}%`};

export const Column = styled(ProportionateBox)`
  flex-direction: column;
  min-width: 200px;
  padding-bottom: ${({ gutter }) => (gutter ? 2 * gutter : 0) + 'px'};
`

export const ImageBox = styled(ProportionateBox)`
  width: ${({ gutter }) => `calc(100% - ${gutter ? gutter * 2 : 0}px)`};
`

// max-width: ${({ col, gutter }) =>
//   `calc(${parsePercentage(col)}% - ${gutter ? 2 * gutter : 0}px)`};

Column.propTypes = {
  column: PropTypes.number.isRequired,
  gutter: PropTypes.number,
}

const typographyDefaults = css`
  line-height: 1.5;
  font-family: ${T.fontFamily};
  font-weight: 500;
  text-align: ${({ ta }) => textAlignMap[ta] || textAlignMap['c']};
`

export const H1 = styled.h1`
  ${typographyDefaults};
  font-size: ${({ fs }) => fs || T.fontSizeDefaults['h1']};
  color: ${({ c }) => c || T.fontColorDefaults['h1']};
  padding: ${({ p }) => p || 0};
  margin: ${({ m }) => m || 0};
`

export const H3 = styled.h1`
  ${typographyDefaults};
  font-size: ${({ fs }) => fs || T.fontSizeDefaults['h3']};
  color: ${({ c }) => c || T.fontColorDefaults['h3']};
  padding: ${({ p }) => p || 0};
  margin: ${({ m }) => m || 0};
`

export const P = styled.p`
  ${typographyDefaults};
  font-size: ${({ fs }) => fs || T.fontSizeDefaults['p']};
  color: ${({ c }) => c || T.fontColorDefaults['p']};
  padding: ${({ p }) => p || 0};
  margin: ${({ m }) => m || 0};
`

export const A = styled.a`
  ${typographyDefaults};
  font-size: ${({ fs }) => fs || T.fontSizeDefaults['a']};
  color: ${({ c }) => c || T.fontColorDefaults['a']};
  padding: ${({ p }) => p || 0};
  margin: ${({ m }) => m || 0};
  text-decoration: none;
  &:hover {
    color: ${({ c }) => c || T.hoverAccentColor};
    text-decoration: underline;
  }
`

export const Button = styled.button`
  ${typographyDefaults};
  color: ${({ c }) => (c ? c : T.white)};
  font-size: ${({ fs }) => fs || T.fontSizeDefaults['button']};
  background-color: ${({ bgc }) => (bgc ? bgc : T.accentColor)};
  padding: ${({ p }) => p || 0};
  margin: ${({ m }) => m || 0};
  border-color: ${({ bgc }) => (bgc ? bgc : T.accentColor)};
  text-decoration: none;
  cursor: pointer;
  padding: 9px 18px;
  border: 1px solid transparent;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ bgc }) => (bgc ? bgc : T.hoverAccentColor)};
    borer-color: ${({ bgc }) => (bgc ? bgc : T.hoverAccentColor)};
  }
`
