import React from 'react'

import { directions, sortings } from '../../constants/index'
import * as S from './style'

const ClearCacheButton = () => (
  <ActionButton handler={_e => window.localStorage.clear()}>
    Clear Apollo Cache
  </ActionButton>
)

const ActionButton = ({ active, children, entity, handler, ...props }) => (
  <S.Button active={active} onClick={handler} {...props}>
    {entity} {children}
  </S.Button>
)

export function ControlPanel({
  activeFilters,
  activeSorting,
  labels,
  handleFilter,
  handleSort,
}) {
  return (
    <S.Container>
      <Filter
        activeFilters={activeFilters}
        handleFilter={handleFilter}
        filters={labels}
      />
      <Sort activeSorting={activeSorting} handleSort={handleSort} />
      <S.H3>Other controls:</S.H3>
      <ClearCacheButton />
    </S.Container>
  )
}

function Filter({ activeFilters, filters, handleFilter }) {
  const renderFilters = Object.entries(filters).map(([id, name]) => (
    <ActionButton
      key={id}
      active={activeFilters[name]}
      handler={handleFilter(name)}
      entity={name}
      m='5px'
    />
  ))

  return (
    <S.Row>
      <S.H3 m='10px 0'>Filter by label:</S.H3>
      <S.Row p='0'>{renderFilters}</S.Row>
    </S.Row>
  )
}

const getIconByDirection = {
  [directions.DESC]: '↓',
  [directions.ASC]: '↑',
  [directions.NONE]: null,
}

function Sort({ activeSorting, handleSort }) {
  const renderColumns = Object.keys(sortings).map(column => {
    const direction = activeSorting[column]
    const [[sortColumn, sortDirection]] = Object.entries(activeSorting)
    const isActive = column === sortColumn && sortDirection !== directions.NONE

    return (
      <ActionButton
        active={isActive}
        key={column}
        handler={handleSort(column, direction)}
        entity={column}
        m='0 10px'
      >
        {' '}
        {isActive && getIconByDirection[sortDirection]}
      </ActionButton>
    )
  })

  return (
    <S.Row>
      <S.H3 m='10px 0'>Sort by field:</S.H3>
      <S.Row p='0'>{renderColumns}</S.Row>
    </S.Row>
  )
}
