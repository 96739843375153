export const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_ACCESS_TOKEN;
export const GRAPHQL_SERVER_ENDPOINT = "https://api.github.com/graphql";

export const directions = { ASC: "ASC", DESC: "DESC", NONE: undefined };

export const sortings = {
  createdAt: directions.DESC,
  updatedAt: directions.NONE,
  title: directions.NONE,
  comments: directions.NONE
};

export const actions = {
  ADD_FILTER: "ADD_FILTER",
  NEW_SORTING: "NEW_SORTING",
  FILTER_ISSUES: "FILTER_ISSUES",
  SORT_ISSUES: "SORT_ISSUES"
};
