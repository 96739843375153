import styled from 'styled-components'

import * as S from '../../style'

export const Container = styled(S.Container)`
  padding: 20px;
`
export const PageContainer = S.PageContainer

export const H3 = S.H3

export const P = S.P
