import React, { useEffect, useState } from 'react'
import * as S from '../style'

const makeRedirectUrl = (href, values) =>
  `${href}${values.owner}/${values.repo}`

export function Home() {
  const [values, setValues] = useState({ owner: '', repo: '' })

  const handleChange = name => e => {
    e.persist()
    setValues(vs => ({ ...vs, [name]: e.target.value }))
  }

  useEffect(() => console.log('values: ', values))

  return (
    <S.PageContainer>
      <S.Container>
        <S.Input
          name='owner'
          value={values.owner}
          placeholder='Enter GitHub user or organization...'
          onChange={handleChange('owner')}
        />
        <S.Input
          name='repo'
          value={values.repo}
          placeholder='Enter repository (ideally with issues)...'
          onChange={handleChange('repo')}
        />
        <S.Button
          onClick={() =>
            (window.location = makeRedirectUrl(window.location.href, values))
          }
        >
          See the issues!
        </S.Button>
      </S.Container>
    </S.PageContainer>
  )
}
