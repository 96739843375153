import React from 'react'
import * as S from './style'
import { A, ImageBox, Row } from '../../style'

const formatDate = dateString => {
  return String(new Date(dateString))
    .split(' ')
    .slice(0, 5)
    .join(' ')
}

function Label({ children }) {
  return children
}

function Labels({ labels }) {
  return (
    <S.P>
      <S.Span>Labels:</S.Span>
      {!labels.length ? (
        <span> [None] </span>
      ) : (
          labels.map(label => <Label key={label}>{label}</Label>)
        )}
    </S.P>
  )
}

const col = 3
const gutter = 16

function Issue({ issue }) {
  return (
    <S.Column col={col} gutter={gutter} m='0 0 50px 0'>
      <ImageBox col={col} gutter={gutter}>
        <img
          style={{ width: '100%', maxWidth: '100%' }}
          src={issue.participants}
          alt={issue.title}
        />
      </ImageBox>

      <S.P fs='1.25em'>
        <strong>
          <A href={issue.url} target='_blank'>
            {issue.title}
          </A>
        </strong>
      </S.P>
      <S.P>Created at: {formatDate(issue.createdAt)}</S.P>
      <S.P>Updated at: {formatDate(issue.updatedAt)}</S.P>
      <S.P>Total comments: {issue.comments}</S.P>
      <Labels labels={issue.labels} />
    </S.Column>
  )
}

export function Issues({ issues }) {
  return (
    <Row m='100px 0 0 0' align='start'>
      {issues.map(issue => (
        <Issue issue={issue} key={issue.id} />
      ))}
    </Row>
  )
}
