import React, {useCallback, useEffect, useReducer, useRef} from 'react'
import * as R from 'ramda'
import {useQuery} from '@apollo/react-hooks'

import {ControlPanel} from '../ControlPanel'
import {Header} from '../Header'
import {Issues} from '../Issues'
import {Loading} from '../Loading'
import {actions} from '../../constants'
import {issuesQuery as query} from '../../state/queries'
import {reducer, createInitialState} from '../../state/reducers'
import {getIssues, getNextDirection, getUniqueLabels} from '../../selectors'
import * as S from './style'

export function App({owner, repo}) {
  const {loading, error, data} = useQuery(query, {
    variables: {repo, owner},
  })
  const labels = getUniqueLabels(data)
  const allIssues = getIssues(data)
  const [state, dispatch] = useReducer(
    reducer,
    createInitialState({
      // :thumbs_up:
      filters: R.values(labels),
      issues: R.values(allIssues),
    }),
  )

  const firstRender = useRef(true)

  const handleFilter = useCallback(
    filter => _e => dispatch({type: actions.TOGGLE_FILTER, payload: filter}),
    [],
  )

  const handleSort = useCallback(
    (column, direction) => _e =>
      dispatch({
        type: actions.NEW_SORTING,
        payload: [column, getNextDirection(direction)],
      }),
    [],
  )

  useEffect(() => {
    dispatch({type: actions.FILTER_ISSUES, payload: allIssues})
    // disable because adding state.issues to dep array makes things less clear and causes unnecessary re-renders; TODO: refactor to not use this dependency
    // eslint-disable-next-line
  }, [state.activeFilters, loading])

  // TODO: Make this better, or just remove (probably remove)
  useEffect(() => {
    if (firstRender.current) return () => null
    dispatch({type: actions.SORT_ISSUES, payload: state.issues})
    // disable because adding state.issues to dep array makes things less clear and causes unnecessary re-renders; TODO: refactor to not use this dependency
    // eslint-disable-next-line
  }, [state.activeSorting])

  useEffect(() => {
    firstRender.current = false
  }, [])

  return (
    <S.PageContainer>
      <Header />
      {loading ? (
        <Loading />
      ) : error ? (
        <S.H3>You've got an issue! Try refreshing the page.</S.H3>
      ) : (
        <>
          <S.P fs='1em'>
            # of visible issues (out of 100):{' '}
            <strong>{state.issues.length}</strong>
          </S.P>
          <ControlPanel
            labels={labels}
            handleFilter={handleFilter}
            handleSort={handleSort}
            activeFilters={state.activeFilters}
            activeSorting={state.activeSorting}
          />
          <Issues issues={state.issues} />
        </>
      )}
    </S.PageContainer>
  )
}
