import React, { useState, useEffect } from 'react'
import { App } from './App'
import { Home } from './Home'

function usePathname(location) {
  const _pathname = location.pathname
  const [, _owner, _repo] = _pathname.split('/')

  const [owner, setOwner] = useState(_owner)
  const [repo, setRepo] = useState(_repo)

  useEffect(() => {
    setOwner(_owner)
  }, [_owner, setOwner])

  useEffect(() => {
    setRepo(_repo)
  }, [_repo, setRepo])

  return { owner, repo }
}

export function Router() {
  const { owner, repo } = usePathname(window.location)

  return !owner && !repo ? <Home /> : <App owner={owner} repo={repo} />
}
