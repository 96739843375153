import styled from 'styled-components'

import * as S from '../../style'

export const P = styled(S.P)`
  font-size: ${({ fs }) => fs || '1em'};
`

export const Container = styled(S.Container)``

export const Button = styled(S.Button)`
  text-decoration: underline;
  background: ${({ active }) => (active ? S.Theme.accentColor : 'grey')};
`

export const Card = styled.div`
  width: 100;
`

export const Span = styled.span`
  margin-right: 6px;
  text-decoration: underline;
`

export const Column = styled(S.Column)`
  box-shadow: 0 30px 72px -19px rgba(0, 0, 0, 0.36);
`
