import { actions, sortings } from '../constants/index'
import { sortByColumn } from '../selectors'

export const createInitialState = ({ filters, issues }) => ({
  activeFilters: filters.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
  activeSorting: { createdAt: sortings.createdAt },
  issues,
})

export function reducer(state, { type, payload }) {
  switch (type) {
    case actions.TOGGLE_FILTER:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [payload]: !state.activeFilters[payload],
        },
      }
    case actions.NEW_SORTING:
      const [column, direction] = payload
      return {
        ...state,
        activeSorting: { [column]: direction },
      }
    case actions.FILTER_ISSUES:
      const activeFilterCount = Object.keys(state.activeFilters)
        .map(key => state.activeFilters[key])
        .filter(value => !!value).length

      const issues =
        activeFilterCount === 0
          ? Object.keys(payload).map(id => payload[id])
          : Object.keys(payload)
            .map(id => payload[id])
            .filter(issue =>
              issue.labels.some(label => state.activeFilters[label]),
            )

      return {
        ...state,
        issues,
      }
    case actions.SORT_ISSUES:
      return {
        ...state,
        issues: sortByColumn(state.activeSorting)(payload),
      }

    default:
      throw new Error(`Action type ${type} not supported!`)
  }
}
